// app/javascript/controllers/social_share_controller.js
import { Controller } from "stimulus"
// <!-- Social Share Button -->
export default class extends Controller {

    static values = { title: String, url: String, text: String }

    // hide the share button if it's not supported by a browser
    connect() {
        console.log('social share connect');
        if (!navigator.share) {
            this.element.hidden = true;
        }
    }

    share(event) {
        // prevent form submit & redirect
        console.log(this.urlValue);
        console.log(this.textValue)
        console.log(this.titlevalue)
        event.preventDefault();
        // share!
        navigator.share({ title: this.titleValue, url: this.urlValue, text: this.textValue });
    }
}