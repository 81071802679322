import ApplicationController from './application_controller'

export default class extends ApplicationController {
  // static targets = ['focus']
  submit(e) {
    e.preventDefault()
    document.body.classList.add('wait');
    console.log('before reflex');


    this.stimulate('QuoteReflex#quote').then(() => {
      // this.element.reset()
      console.log('we are back') /
        // initMap();
        pageSetup();
      document.body.classList.remove('wait');
      console.log('after reflex');


      // optional: set focus on the freshly cleared input
      // this.focusTarget.focus()
    })
  }
  reset(e) {
    e.preventDefault()
    document.body.classList.add('wait');
    console.log('before reflex');


    this.stimulate('QuoteReflex#reset').then(() => {
      // this.element.reset()
      console.log('we are back')
      initMap();
      pageSetup();
      document.body.classList.remove('wait');
      console.log('after reflex');


      // optional: set focus on the freshly cleared input
      // this.focusTarget.focus()
    })
  }


}