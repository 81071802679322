/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

import Vue from 'vue'
import App from '../app.vue'
import { createApp } from 'vue'

import ReservationsCalendar from '../reservations_calendar.vue'
import BlocksCalendar from '../blocks_calendar.vue'
import AvailabilityCalendar from '../availability_calendar.vue'
// document.addEventListener('DOMContentLoaded', () => {
//   const app = new Vue({
//     render: h => h(Calendar)
//   }).$mount()
//   document.getElementById("vue-app").appendChild(app.$el)

//   console.log(app)
// })

export default () => {
  document.addEventListener('DOMContentLoaded', () => {
    console.log('HERE');
      const resevationsApp = createApp(ReservationsCalendar)
      resevationsApp.mount('#vue-reservations-calendar')
      const blocksApp = createApp(BlocksCalendar)
      blocksApp.mount('#vue-blocks-calendar')
      const availabilityApp = createApp(AvailabilityCalendar)
      availabilityApp.mount('#vue-availability-calendar')
  })
}


// The above code uses Vue without the compiler, which means you cannot
// use Vue to target elements in your existing html templates. You would
// need to always use single file components.
// To be able to target elements in your existing html/erb templates,
// comment out the above code and uncomment the below
// Add <%= javascript_pack_tag 'hello_vue' %> to your layout
// Then add this markup to your html template:
//
// <div id='hello'>
//   {{message}}
//   <app></app>
// </div>


// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// document.addEventListener('DOMContentLoaded', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: {
//       message: "Can you say hello?"
//     },
//     components: { App }
//   })
// })
//
//
//
// If the project is using turbolinks, install 'vue-turbolinks':
//
// yarn add vue-turbolinks
//
// Then uncomment the code block below:
//
// import TurbolinksAdapter from 'vue-turbolinks'
// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// Vue.use(TurbolinksAdapter)
//
// document.addEventListener('turbolinks:load', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: () => {
//       return {
//         message: "Can you say hello?"
//       }
//     },
//     components: { App }
//   })
// })
