// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "core-js/stable";
import "regenerator-runtime/runtime";
require("@rails/ujs").start()
import "jquery"
import "bootstrap"
import "@hotwired/turbo-rails"

// 
// require("@rails/activestorage").start()
require("channels")
require("jquery")
require("bootstrap")
require("@hotwired/turbo-rails")
require("ion-rangeslider")
// require("@googlemaps/markerwithlabel")
var card = require("card");

import LogRocket from 'logrocket';
LogRocket.init('wkgzl7/bennington-properties');

import { Turbo } from "@hotwired/turbo-rails"
window.Turbo = Turbo

import debounced from 'debounced'
debounced.initialize()

// window.vegaEmbed = require("vega-embed").default

console.log('hello from webpacker update')
// import { MarkerWithLabel } from "@googlemaps/markerwithlabel"
// var MarkerWithLabel = require('@googlemaps/markerwithlabel');
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


document.addEventListener("DOMContentLoaded", function (event) {


  console.log('document ready')
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  })

  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
  let searchParams = new URLSearchParams(window.location.search);

  const urlParams = new URLSearchParams(window.location.search);
  const startDateParam = urlParams.get("start_date");
  const endDateParam = urlParams.get("end_date");

  if (startDateParam && endDateParam) {
    // Parse the start and end dates with Moment.js
    const start = moment(startDateParam).startOf('day'); // Beginning of the start_date
    const end = moment(endDateParam).endOf('day');       // End of the end_date

    // Log the results or use as needed
    console.log("Start of Day (Start Date):", start.format());
    console.log("End of Day (End Date):", end.format());
  } else {
    console.error("Start date or end date is missing in the URL parameters.");
  }
  console.error('searchParams', searchParams)
  // console.log('start', start);

  // var start = moment('#{@start_date}')
  // var end = moment('#{@end_date}');
  window.propertyComparison = searchParams.get('property_comparison');
  window.timeComparison = searchParams.get('time_comparison');
  window.chartVersion = searchParams.get('chart_version');
  console.log('page loaded');
  window.startDate = start;
  window.endDate = end;


  // function cb(start, end) {
  //   $('#reportrange span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
  // }

  // $('#reportrange').daterangepicker({
  //   startDate: start,
  //   endDate: end,
  //   showDropdowns: true,
  //   ranges: {
  //     'Year To Date': [moment().startOf('year'), moment()],
  //     'Last 12 Months': [moment().subtract(12, 'months'), moment()],
  //     'Last 6 Months': [moment().subtract(6, 'months'), moment()],
  //     'Last 3 Months': [moment().subtract(3, 'months'), moment()],
  //     'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  //     'Last 30 Days': [moment().subtract(30, 'days'), moment()]
  //   }

  // }, cb);


  // function newUrl(start, end) {
  //   url = `${window.location.protocol}//${window.location.host}/properties/#{@track_unit.slug}/dashboard_new?start_date=${window.startDate.format('YYYY-MM-DD')}&end_date=${window.endDate.format('YYYY-MM-DD')}`
  //   if (window.propertyComparison) {
  //     url += `&property_comparison=${window.propertyComparison}`
  //   }
  //   if (window.timeComparison) {
  //     url += `&time_comparison=${window.timeComparison}`
  //   }
  //   if (window.chartVersion) {
  //     url += `&chart_version=${window.chartVersion}`
  //   }
  //   console.log('newUrl', url)
  //   return url
  // }


  // $('#reportrange').daterangepicker({
  //   startDate: start,
  //   endDate: end,
  //   showDropdowns: true,
  //   ranges: {
  //     'Year To Date': [moment().startOf('year'), moment()],
  //     'Last 12 Months': [moment().subtract(12, 'months'), moment()],
  //     'Last 6 Months': [moment().subtract(6, 'months'), moment()],
  //     'Last 3 Months': [moment().subtract(3, 'months'), moment()],
  //     'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  //     'Last 30 Days': [moment().subtract(30, 'days'), moment()]
  //   }

  // }, cb);
});
import "controllers"

import initVueApp from "./hello_vue.js"


initVueApp()

